import React, { useEffect, useState } from 'react';
import Mantenimiento from "../../../pago-rapido/Components/Mantenimiento/Mantenimiento";
import Spinner from "../../../pago-rapido/Components/Spinner/Spinner";
import  * as Api   from '../../../pago-rapido/api';

const Layout = ({children}) => {

    const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const getIsMantenimiento = async () => {

      const data = await Api.GetMantenimiento();
      
      setTimeout(() => {
        if (typeof data !== 'undefined') {
          setLoading(false);
          setDisabled(data.data.mantenimiento);
          setMessage(data.data.message);
        }
      }, 1000);
    }

    getIsMantenimiento();

  }, []);

  return (
    <>{loading ?
        <div className="text-center"><Spinner></Spinner></div> :
        disabled ? <Mantenimiento message={message} />
          : children }</>
  )
}

export default Layout