import hookup from "./hookup";

export const SignIn = async (data) => {
  return await hookup("get", "web/login-pago-rapido", data);
};
export const ListQuota = async (data) => {
  return await hookup("get", "web/cuotas-pago-rapido", data);
};
export const BuyPagoRapido = async (data) => {
  return await hookup("post", "mall/start/pago-rapido", data);
};
export const getDetailtsPaymentGeneric = async (data) => {
  return await hookup("get", "mall/get/detail-buy", data);
}

export const GetMantenimiento = async () => {
  return await hookup("get", `web/Mantenimiento`, {});
}