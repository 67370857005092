import React, { Component } from 'react';
import { Collapse, Spinner, Button } from 'reactstrap';
import validator from 'validator';
import TableCuota from '../Components/TableCuota';
import TablePie from '../Components/TablePie';
//import Api from './../../service/Api';
import Session from '../../service/Session';
import Footer from '../Components/Footer/Footer.jsx';
import Welcome from '../Components/Welcome/Welcome.jsx';
import Headboard from '../Components/Headboard/Headboard.jsx';
import Additional from '../Components/Additional/Additional.jsx';
import * as Api from "../api";
import {
    Modal,
    ModalBody
  } from 'reactstrap';

var checkboxes = {};
var parametro = 0;

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCuota: {},
            collapse: 1,
            collapses: {},
            items: {},
            nombre: Session.getInfoUserPagoRapido().Pusuario,
            rut: Session.getInfoUserPagoRapido().Prut,
            token_ws: 0,
            url: '',
            disabledPagar: true,
            show: true,
            cuotas: {
                0: {},
                1: {},
                2: {},
                3: {},
                4: {}
            },
            montoTotal: 0,
            bloqueo: [],
            checkDisabled: [],
            paymentType: 1,
            openmodal: true,
            ivas: 2023
        }
        this.toggle = this.toggle.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onClickPagar = this.onClickPagar.bind(this);
        this.getCuotas = this.getCuotas.bind(this);
        this.calculaMontoTotal = this.calculaMontoTotal.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.setCheckboxes = this.setCheckboxes.bind(this);
        this.checkOpcion = this.checkOpcion.bind(this);
    }
    componentWillMount() {
        this.getCuotas();
    }
    Logout() {
        localStorage.removeItem('Prut');
        localStorage.removeItem('Pcorreo');
        localStorage.removeItem('Pusuario');
        localStorage.removeItem('token');
        window.location.href = '/';
    }
    checkOpcion(e, opcion, contrato) {
        const { bloqueo } = this.state;
        bloqueo[contrato] = opcion;
        if (e.target.checked) {
            this.setState({
                bloqueo: bloqueo
            })
        } else {
            delete this.state.bloqueo[contrato];
        }
    }
    toggle(e, tab) {
        e.preventDefault();
        if (this.state.collapse !== tab) {
            this.setState({
                collapse: tab
            });
        } else {
            this.setState({
                collapse: 0
            });
        }
    }
    async getCuotas() {
        const data = await Api.ListQuota({ rut: this.state.rut });

        let defaultbloqueo;
        
        data.map((item) => {

            item.isOpen = true;

            defaultbloqueo = this.state.bloqueo;
            defaultbloqueo[item.contrato] = 1;

            this.setState({
                bloqueo: defaultbloqueo
            })

        })

        this.setState({
            items: data,
            show: false,
        })

    }
    async onClickPagar() {
        const { rut, email, nombre, selectedCuota, montoTotal } = this.state;
        let cuotas = selectedCuota;
        if (email === '' || typeof email === 'undefined') {
            this.setState({
                errorEmail: 'error',
                type: 'vacio'
            })
            return false;
        }
        if (!validator.isEmail(email)) {
            this.setState({
                errorEmail: 'error',
                type: 'valido'
            })
            return false;
        }
        this.setState({
            show: true,
            disabledPagar: true
        })
        const data = await Api.BuyPagoRapido({
            rut: rut,
            email: email,
            nombre: nombre,
            amount: montoTotal,
            cuotasSeleccionadas: JSON.stringify(cuotas)
        });
        console.info(data)
        this.setState({
            token_ws: data.token_ws,
            url: data.next_page
        });
        document.getElementById("form_webpay").submit();
    }

    onClick(e, items) {
        var objeto = {};
        const { bloqueo, checkDisabled } = this.state;
        if (e) {
            items.forEach(todo => {
                if (todo.CONCEPTO_RECAUDACION === 'MPU') {
                    bloqueo[todo.NUMERO_CONTRATO] = 2;
                    checkDisabled[todo.NUMERO_CONTRATO] = true;
                    this.setState({
                        bloqueo: bloqueo,
                        checkDisabled: checkDisabled
                    })
                } else if (todo.CONCEPTO_RECAUDACION === 'MNT') {
                    bloqueo[todo.NUMERO_CONTRATO] = 1;
                    checkDisabled[todo.NUMERO_CONTRATO] = true;
                    this.setState({
                        bloqueo: bloqueo,
                        checkDisabled: checkDisabled
                    })
                }
                objeto = {
                    'NUMERO_CONTRATO': todo.NUMERO_CONTRATO,
                    'NUMERO_CUOTA': todo.NUMERO_CUOTA,
                    'CONCEPTO_RECAUDACION': todo.CONCEPTO_RECAUDACION,
                    'MONTO_CUOTA': parseInt(todo.MONTO_CUOTA),
                    'NUMERO_DOCUMENTO': todo.NUMERO_DOCUMENTO,
                    'CUOTA_MOROSA': todo.CUOTA_MOROSA,
                    'CODIGO_COMERCIO': todo.CODIGO_COMERCIO,
                };
                this.state.selectedCuota[todo.NUMERO_CONTRATO + '-' + todo.NUMERO_CUOTA + '-' + todo.CONCEPTO_RECAUDACION] = objeto;
            });
        } else {
            var estado = 0;
            items.forEach((todo) => {
                delete this.state.selectedCuota[todo.NUMERO_CONTRATO + '-' + todo.NUMERO_CUOTA + '-' + todo.CONCEPTO_RECAUDACION];
                if (todo.CONCEPTO_RECAUDACION === 'MPU') {
                    bloqueo[todo.NUMERO_CONTRATO] = 2;
                    checkDisabled[todo.NUMERO_CONTRATO] = false;
                    this.setState({
                        bloqueo: bloqueo,
                        checkDisabled: checkDisabled
                    })
                }
                if (todo.CONCEPTO_RECAUDACION === 'MNT') {
                    const { selectedCuota } = this.state;
                    Object.keys(selectedCuota).map(item => {
                        if (item.includes('MNT')) {
                            estado++;
                        }
                    })
                    if (estado === 0) {
                        bloqueo[todo.NUMERO_CONTRATO] = 1;
                        checkDisabled[todo.NUMERO_CONTRATO] = false;
                        this.setState({
                            bloqueo: bloqueo,
                            checkDisabled: checkDisabled
                        })
                    }
                }
            })

        }
    }
    calculaMontoTotal() {
        var valor = 0;
        const { selectedCuota } = this.state;
        Object.keys(selectedCuota).map((item) => (
            valor += parseInt(selectedCuota[item].MONTO_CUOTA)
        ))
        this.setState({
            disabledPagar: valor > 0 ? false : true,
            montoTotal: valor
        })
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value });
    }
    setCheckboxes(NUMERO_CONTRATO, NUMERO_CUOTA, type, estado) {
        var objeto = {};
        objeto = { estado };
        var pos = 0;
        if (type === 'F') {
            pos = 0;
        } else if (type === 'M') {
            pos = 1;
        } else if (type === 'CM') {
            pos = 2;
        } else {
            pos = 3;
            objeto.estado = false;
        }
        this.state.cuotas[pos][NUMERO_CONTRATO + "-" + type + "-" + NUMERO_CUOTA] = objeto;
        checkboxes = this.state.cuotas;
    }

    renderMensajeIVA() {
        const date = new Date();

        if(date.getFullYear() >= this.state.ivas ) {
            return (<>
                    <div className='row'>
                        <div className="col-8">
                            (*) Monto del <strong style={{letterSpacing: '1.5px'}}>IVA A BENEFICIO FISCAL</strong> calculado sobre los precios de los servicios afectos a IVA. Para más información sobre estos servicios visite <a href="https://sacramental.cl" target="_blank">www.sacramental.cl </a> o comuníquese con nosotros al <span style={{color: '#000',fontWeight: 'bold'}}>800 200 151</span> o en cualquiera de nuestras oficinas y cementerios parque.
                        </div>
                    </div>
                </>)
        }

        
        return (<></>)
       
    }

    render() {
        const { items, email, disabledPagar, show, errorEmail, cuotas, montoTotal, type, bloqueo, checkDisabled } = this.state;
        return (
            <>
                <Modal isOpen={this.state.openmodal} size={"xl"} >
                    <ModalBody >
                        <Button color="" style={{position: 'absolute' , right: '10px', border: '1px solid gray'}} onClick={(e) => this.setState({openmodal: false})} >
                            Cerrar
                        </Button>
                        <div style={{fontSize: '28px' , fontWeight: 'bold', color: "#000"}}>NUEVA LEY N° 21.420</div>
                        <p>Estimado Cliente,</p>
                        <br />
                        <div className="row">
                           <div className="col-lg-7 col-md-6">
                                <p>A partir del 01 de enero de 2023 comenzará a regir la <span style={{fontWeight: 'bold', color: '#000'}}>nueva Ley N° 21.420, la cual indica que todos los pagos se servicios se encontrarán afectos a IVA</span></p>
                                <br />
                                <p>Debido a esta ley, Jardín Sacramental está obligado legalmente a incorporar este impuesto a las Mantenciones y otros servicios, los cuales tendrán un <span style={{fontWeight: 'bold', color: '#000'}}>recargo del 19% por concepto de IVA</span>, según lo exigido por la autoridad, lo que será recaudado y entregado al Servicio de Impuestos Internos a beneficio fiscal.</p>
                                <br />
                                <p>Si necesita mas información, puede comunicarse al número telfónico <span style={{fontWeight: 'bold', color: '#800080'}}>800 200 151.</span></p>
                                <br />
                                <p>Conozca la nueva Ley N° 21.420 <a href="https://www.sacramental.cl/a-partir-del-01-de-enero-nuestros-servicios-estaran-afectos-a-iva/" target={"_blank"} ><span style={{fontWeight: 'bold', color: '#800080'}}>aquí</span></a></p>
                            </div>
                            <div className="col-lg-5 col-md-6">
                                <img className="img img-responsive" src="https://sendero-api.gux.cl//storage/imagen_ivas.jpg" alt="Mensaje IVA" height="300"/>
                            </div> 
                        </div>
                    </ModalBody>
                </Modal>
                <form name="form_webpay" action={this.state.url} method="POST" id="form_webpay">
                    <input type="hidden" name="token_ws" value={this.state.token_ws} />
                </form>
                <div className="container my-4" id="dashboard">
                    <div className="content-contratos text-center ">
                        <Welcome />
                        {show ? (
                            <Spinner style={{ width: '5rem', height: '5rem' }} />
                        ) : (
                            items && items.length > 0 ?
                                (
                                    <>
                                        <p style={{textAlign: 'justify' , marginTop: '0px', color:'#fff', padding: '10px', backgroundColor: '#811e6c'}}>Estimados Clientes, a partir del 01 de enero de 2023 comenzará a regir la nueva Ley Nº 21.420, la cual indica que todos los pagos de servicios se encontrarán afectos a IVA.
                                            Debido a esta ley, Jardín Sacramental está obligado legalmente a incorporar este impuesto a las Mantenciones y otros servicios, los cuales tendrán un recargo del 19% por concepto de IVA, según lo exigido por la autoridad, lo que será recaudado y entregado al Servicio de Impuestos Internos a beneficio fiscal.
                                        </p>
                                        <br />
                                        <div className="text-center text-introduccion">
                                            <p>Pagar su cuenta en Sacramental desde ahora será mucho más fácil.<br /> Aquí conocerá sus servicios contratados y cancelar en pocos pasos su cuota de crédito y mantención.</p>
                                            <h4>Servicios Contratados:</h4>
                                        </div>
                                        {
                                            items.map((item, index) => (
                                                <>
                                                    <div key={index}>
                                                        {/* <div onClick={(e) => { this.toggle(e, index + 1) }} className={(parseInt(this.state.collapse) === (index + 1) ? 'open-collapse' : '') + ' text-list'}>
                                                         */}
                                                         <div onClick={(e) => { 
                                                            let aux_items = this.state.items;
                                                            aux_items[index].isOpen = !this.state.items[index].isOpen;
                                                            this.setState({
                                                                items: aux_items
                                                            })
                                                        }} className={this.state.items[index].isOpen === true ? 'open-collapse' : 'text-list'}>
                                                            <Headboard {...item} />
                                                        </div>
                                                        {/* <Collapse isOpen={parseInt(this.state.collapse) === (index + 1) ? true : false}> */}
                                                        <Collapse isOpen={ this.state.items[index].isOpen}>
                                                            <>
                                                                <Additional
                                                                    parque={item.parque}
                                                                    producto={item.producto}
                                                                    contrato={item.contrato}
                                                                    pie={typeof item.pie !== 'undefined' ? item.pie.vigente.adicional : ''}
                                                                />
                                                                <div className="content-contratos__productos">

                                                                    {typeof item.financiamiento !== 'undefined' && (
                                                                        <TableCuota
                                                                            data={item.financiamiento}
                                                                            title={item.financiamiento.titulo}
                                                                            text={'Subtotal'}
                                                                            id={'table-purple'}
                                                                            type={'F'}
                                                                            onClick={this.onClick}
                                                                            calculaMontoTotal={this.calculaMontoTotal}
                                                                            setCheckboxes={this.setCheckboxes}
                                                                            checkboxes={cuotas}
                                                                        />
                                                                    )}
                                                                    <div className="mnt-opcion">
                                                                        {typeof item.mantencion === 'object' && typeof item.mpu === 'object' ? (
                                                                            parseInt(item.mpu.vigente.monto) > 0 && item.mpu.vigente.permite_mpu !== 'NO' ? (
                                                                                <>
                                                                                    <div className="row opciones">
                                                                                        <div className="col-12">
                                                                                            <h3>Seleccione la opción</h3>
                                                                                        </div>
                                                                                        <div className="col-6">
                                                                                            <input
                                                                                                name={"opcion" + item.contrato}
                                                                                                type="radio"
                                                                                                onClick={(e) => { this.checkOpcion(e, 1, item.contrato, index) }}
                                                                                                disabled={checkDisabled[item.contrato]}
                                                                                                defaultChecked
                                                                                            />
                                                                                            <h4>Mantención Anual</h4>
                                                                                            <p>Corresponde al valor que cada año usted cancela, el cuál nos permite mantener cada mes los espacios comunes de nuestro Cementerio Parque. Esto considera el mejoramiento de caminos, paisajismo, riego y corte de césped.</p>
                                                                                        </div>
                                                                                        <div className="col-6">
                                                                                            <input
                                                                                                name={"opcion" + item.contrato}
                                                                                                type="radio"
                                                                                                onClick={(e) => { this.checkOpcion(e, 2, item.contrato, index) }}
                                                                                                disabled={checkDisabled[item.contrato]}
                                                                                            />
                                                                                            <h4>Mantención Perpetua</h4>
                                                                                            <p>Corresponde a un beneficio al que usted puede optar para liberarse del pago anual de la mantención de nuestro Cementerio Parque de manera definitiva.</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={"pantalla" + (parseInt(bloqueo[item.contrato]) !== 1 ? ' verifica' : '')}>
                                                                                        <div className={"bloque-pago inactivo"}> </div>
                                                                                        <TableCuota
                                                                                            data={item.mantencion}
                                                                                            title={item.mantencion.titulo}
                                                                                            text={'Subtotal'}
                                                                                            id={'table-purple'}
                                                                                            type={'M'}
                                                                                            onClick={this.onClick}
                                                                                            calculaMontoTotal={this.calculaMontoTotal}
                                                                                            setCheckboxes={this.setCheckboxes}
                                                                                            checkboxes={cuotas}
                                                                                        />
                                                                                    </div>
                                                                                    <div className={"pantalla" + (parseInt(bloqueo[item.contrato]) !== 2 ? ' verifica' : '')}>
                                                                                        <div className={"bloque-pago inactivo"}> </div>
                                                                                        <TableCuota
                                                                                            data={item.mpu}
                                                                                            title={item.mpu.titulo}
                                                                                            text={'Subtotal'}
                                                                                            id={'table-purple'}
                                                                                            type={'MP'}
                                                                                            onClick={this.onClick}
                                                                                            calculaMontoTotal={this.calculaMontoTotal}
                                                                                            setCheckboxes={this.setCheckboxes}
                                                                                            checkboxes={cuotas}
                                                                                        />
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                <TableCuota
                                                                                    data={item.mantencion}
                                                                                    title={item.mantencion.titulo}
                                                                                    text={'Subtotal'}
                                                                                    id={'table-purple'}
                                                                                    type={'M'}
                                                                                    onClick={this.onClick}
                                                                                    calculaMontoTotal={this.calculaMontoTotal}
                                                                                    setCheckboxes={this.setCheckboxes}
                                                                                    checkboxes={cuotas}
                                                                                    parametro={parametro}
                                                                                />
                                                                            )
                                                                        ) : typeof item.mantencion !== 'undefined' && (
                                                                            <TableCuota
                                                                                data={item.mantencion}
                                                                                title={item.mantencion.titulo}
                                                                                text={'Subtotal'}
                                                                                id={'table-purple'}
                                                                                type={'M'}
                                                                                onClick={this.onClick}
                                                                                calculaMontoTotal={this.calculaMontoTotal}
                                                                                setCheckboxes={this.setCheckboxes}
                                                                                checkboxes={cuotas}
                                                                                parametro={parametro}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                    {typeof item.cuponera !== 'undefined' && (
                                                                        <TableCuota
                                                                            data={item.cuponera}
                                                                            title={item.cuponera.titulo}
                                                                            text={'Subtotal'}
                                                                            id={'table-purple'}
                                                                            type={'CM'}
                                                                            onClick={this.onClick}
                                                                            calculaMontoTotal={this.calculaMontoTotal}
                                                                            setCheckboxes={this.setCheckboxes}
                                                                            checkboxes={cuotas}
                                                                        />
                                                                    )}
                                                                    {typeof item.vitalicia !== 'undefined' && item.vitalicia.vigente.permite_vit !== 'NO' && (
                                                                        <TableCuota
                                                                            data={item.vitalicia}
                                                                            title={item.vitalicia.titulo}
                                                                            text={'Subtotal'}
                                                                            id={'table-purple'}
                                                                            type={'VT'}
                                                                            onClick={this.onClick}
                                                                            calculaMontoTotal={this.calculaMontoTotal}
                                                                            setCheckboxes={this.setCheckboxes}
                                                                            checkboxes={cuotas}
                                                                        />
                                                                    )}
                                                                    {typeof item.servicios !== 'undefined' && item.servicios.vigente.permite_srv !== 'NO' && (
                                                                        <TableCuota
                                                                            data={item.servicios}
                                                                            title={item.servicios.titulo}
                                                                            text={'Subtotal'}
                                                                            id={'table-purple'}
                                                                            type={'SS'}
                                                                            onClick={this.onClick}
                                                                            calculaMontoTotal={this.calculaMontoTotal}
                                                                            setCheckboxes={this.setCheckboxes}
                                                                            checkboxes={cuotas}
                                                                        />
                                                                    )}
                                                                    {typeof item.pie !== 'undefined' && (
                                                                        <TablePie
                                                                            data={item.pie}
                                                                            title={item.pie.titulo}
                                                                            text={'Subtotal'}
                                                                            id={'table-purple'}
                                                                            type={'P'}
                                                                            onClick={this.onClick}
                                                                            calculaMontoTotal={this.calculaMontoTotal}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </>
                                                        </Collapse>
                                                    </div>
                                                </>
                                            ))
                                        }
                                        <div>
                                            <div className="total">
                                                <div className="row">
                                                    <div className="col-6 text-center">Total a pagar</div>
                                                    <div className="col-6 text-center">${new Intl.NumberFormat("de-DE").format(montoTotal)}</div>
                                                </div>
                                            </div>
                                            <div className="checkout">
                                                <div className="row">
                                                    <div className="col-12 col-md-6 offset-md-6 text-center text-md-start">
                                                        <div className='py-2'>
                                                            <p>Ingrese email para recibir comprobante de pago</p>
                                                            <input type="text" name="email" id="email" className={"form-control " + errorEmail} onChange={this.handleInputChange} value={email || ''} />
                                                        </div>
                                                    </div>
                                                    {errorEmail === 'error' && (
                                                        <>
                                                            <div className="col-12 col-md-6 offset-md-6 text-left">
                                                                <br />
                                                                <div className="alert alert-danger">
                                                                    {
                                                                        type === 'vacio' ? 'Debe ingresar e-mail' : 'Debe ingresar e-mail válido'
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                {
                                                    this.renderMensajeIVA()
                                                }
                                                <div className="row">
                                                    <div className="col-12  text-center">
                                                        <Button className="btn btn-verde " onClick={this.onClickPagar} disabled={disabledPagar}>
                                                            {
                                                                show ? (
                                                                    <Spinner style={{ width: '2rem', height: '2rem' }} />
                                                                ) : (
                                                                    <>PAGAR</>
                                                                )
                                                            }
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <Footer />
                                        </div>
                                    </>
                                ) : (
                                    <h1>
                                        NO TIENE CONTRATOS ASOCIADOS
                                    </h1>
                                )
                        )}
                    </div>
                </div>
            </>
        );
    }
}
export default Dashboard;