import React from "react";

const Footer = () => {
  return (
    <div className="contact">
      <div className="row">
        <div className="col-12 offset-md-3 col-md-6 text-center">
          <div className="content_info my-5">
            <h5 className="my-2">Estamos aquí</h5>
            <p className="my-2">Cuando nos necesite</p>
            <h5 className="my-2">CONTÁCTENOS LAS 24 HORAS</h5>
            <a href="tel:800200151"><p className="phone">800 200 151</p></a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
