export function formatRut(rut) {
	if (rut) {
		return rut.replace(/[.-]/g, '').replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4');
	}
	return '';
}

export function unformatRut(rut) {
	return rut ? rut.replace(/\./g, '') : '';
}

export function validateRut(rutValue) {
	const rut = unformatRut(rutValue);
	const rexp = new RegExp(/^([0-9])+-([kK0-9])+$/);
	if (rut.match(rexp)) {
		const RUT = rut.split('-');
		const elRut = RUT[0].split('');
		let factor = 2;
		let suma = 0;
		let dv;
		for (let i = (elRut.length - 1); i >= 0; i--) {
			factor = factor > 7 ? 2 : factor;
			suma += parseInt(elRut[i]) * parseInt(factor++);
		}
		dv = 11 - (suma % 11);
		if (dv === 11) {
			dv = 0;
		} else if (dv === 10) {
			dv = 'k';
		}

		if (dv.toString() === RUT[1].toLowerCase()) {
			return true;
		}
		return false;
	}
	return false;
}