import React from "react";
import { useHistory } from "react-router-dom";
import casa from "./../../Assets/Images/casa.svg";
import Session from "../../../service/Session";
import Logo from "./../Logo/Logo";

const Welcome = () => {
  const history = useHistory();
  const Logout = () => {
    localStorage.removeItem("Prut");
    localStorage.removeItem("Pcorreo");
    localStorage.removeItem("Pusuario");
    localStorage.removeItem("token");
    history.push("/auth/");
  };
  return (
    <>
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-md-4">
          <Logo />
        </div>
      </div>
      <div className="row justify-content-center align-items-center py-4 my-3 border-bottom border-light">
        <div className="col-12 col-md-6">
          <h4 className="text-center text-md-start">
            Bienvenido{" "}
            <strong>{Session.getInfoUserPagoRapido().Pusuario}</strong>
          </h4>
        </div>
        <div className="col-12 col-md-6 text-center text-md-end">
          <p onClick={() => Logout()} style={{ cursor: "pointer" }}>
            <img src={casa} alt="" /> <strong>Cerrar Sesión</strong>
          </p>
        </div>
      </div>
    </>
  );
};
export default Welcome;
